<template>
  <section class="modal">
    <section class="modal-background" @click="$emit('closeAssortmentModal')" />
    <section class="modal-content">
      <section class="columns is-multiline">
        <section class="column is-6-tablet is-12-mobile">
          <article class="productImageSlideshow">
            <v-lazy-image
              class="image"
              :src="
                require(`@/assets/images/assortment/${modalData.imageFull}`)
              "
            />
          </article>
        </section>
        <section class="column is-6-tablet is-12-mobile">
          <article class="productDescription content">
            <h1 class="title is-4 is-spaced">
              {{ modalData.name }}
            </h1>
            <ul style="list-style-type:square">
              <li v-for="(line, index) in modalData.description" :key="index">
                {{ line }}
              </li>
            </ul>
          </article>
        </section>
      </section>
    </section>
    <button
      class="modal-close is-large"
      @click="$emit('closeAssortmentModal')"
      aria-label="close"
    ></button>
  </section>
</template>

<script>
import VLazyImage from 'v-lazy-image';

export default {
  name: 'AssortmentModal',
  props: ['modalData'],
  components: {
    VLazyImage,
  },
};
</script>

<style lang="scss" scoped>
$modal-background: hsla(0, 0%, 16%, 0.33);

.columns {
  margin: 0 auto;
}

@media only screen and (max-width: 768px) {
  .column {
    padding: 0 0.75rem 0 0.75rem;
  }
}

@media only screen and (min-width: 769px) {
  .column {
    padding: 0.75rem 0 0.75rem 0;
  }
  .modal-content {
    width: 1125px;
  }
  .productImageSlideshow {
    height: 500px;
  }
}

.modal-background {
  background-color: $modal-background;
}

.productDescription {
  height: 100%;
  background-color: $white-bis;
  padding: 2rem;
  text-align: left;
}

.productImageSlideshow {
  width: 100%;
  background-color: $white-bis;
}

.image {
  object-fit: cover;
}

img {
  width: 100%;
  height: 100%;
}

p {
  &.has-text-justified {
    text-align: justify;
  }
}

.has-margin-top {
  margin-top: 2rem;
}
</style>
